import React from 'react';
import { Field, reduxForm } from 'redux-form'


import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { renderTextField, renderImageField, renderTextareaField, renderHtmlField, renderSelectField } from './fields/fields';

const required = value => value ? undefined : "Required"

class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: []
        }
    }

    componentDidMount(){
        fetch('https://api.trendy.solutions/admin/fetch/blogCategories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                categories: result
            })
        })

    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="12">
                        <Container fluid className="form-box">
                            <Row>


                                <Col lg="12">
                                    <h3 className="title">Blog</h3>
                                    <h6 className="subtitle">Enter informations</h6>

                                </Col>


                                <Col lg="3" className="input-wrap">

                                    <Field
                                        name="image"
                                        component={renderImageField}
                                    ></Field>
                                </Col>
                                <Col lg="9">
                                    <Row>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="title"
                                                component={renderTextField}
                                                label="Title"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="category"
                                                component={renderSelectField}
                                                label="Category"
                                            >
                                            {
                                                this.state.categories.map((item, idx) => {
                                                    return (
                                                        <option value={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }</Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="shortDescription"
                                                component={renderTextareaField}
                                                label="Short description"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">

                                            <Field
                                                name="content"
                                                component={renderHtmlField}
                                                label="Content"
                                            ></Field>
                                        </Col>


                                    </Row>
                                </Col>

                            </Row>
                        </Container>
                    </Col>





                    <Col lg="12">
                        <button className="button">Save</button>

                    </Col>

                </Row>

            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
