import React from 'react';
import { Field, reduxForm } from 'redux-form'


import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { renderTextField, renderTextareaField, renderHtmlField, renderImageField, renderTagsField, renderGalleryField, renderSelectField, renderDateTimeField, renderMultiSelectField, renderCheckField } from './fields/fields';

const required = value => value ? undefined : "Required"

class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            technologies: []
        }
    }

    componentDidMount() {
        fetch('https://api.trendy.solutions/admin/fetch/categories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                categories: result
            })
        })
        fetch('https://api.trendy.solutions/admin/fetch/technologies', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                technologies: result
            })
        })

    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="12">
                        <Container fluid className="form-box">
                            <Row>


                                <Col lg="12">
                                    <h3 className="title">Product</h3>
                                    <h6 className="subtitle">Enter informations</h6>

                                </Col>


                                <Col lg="4" className="input-wrap">

                                    <Field
                                        name="image"
                                        component={renderImageField}
                                    ></Field>
                                </Col>
                                <Col lg="8" >
                                    <Row>

                                        <Col lg="10" className="input-wrap">
                                            <Field
                                                name="name"
                                                component={renderTextField}
                                                label="Name"
                                            ></Field>
                                        </Col>
                                        <Col lg="2" className="input-wrap">
                                            <Field
                                                name="sku"
                                                component={renderTextField}
                                                label="SKU"
                                            ></Field>
                                        </Col>

                                        <Col lg="6" className="input-wrap">
                                            <Field
                                                name="oldPrice"
                                                component={renderTextField}
                                                label="Old price"
                                            ></Field>
                                        </Col>
                                        <Col lg="6" className="input-wrap">
                                            <Field
                                                name="price"
                                                component={renderTextField}
                                                label="Price"
                                            ></Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="shortDescription"
                                                component={renderTextareaField}
                                                label="Short description"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="livePreviewLink"
                                                component={renderTextField}
                                                label="Live preview link"
                                            ></Field>
                                        </Col>


                                    </Row>
                                </Col>
                                <Col lg="4" className="input-wrap">
                                    <Row>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="category"
                                                component={renderSelectField}
                                                label="Category"
                                            >{
                                                    this.state.categories.map((item, idx) => {
                                                        return (
                                                            <option value={item._id}>{item.name}</option>
                                                        )
                                                    })
                                                }</Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="technologies"
                                                component={renderMultiSelectField}
                                                label="Technologies"
                                            >{
                                                    this.state.technologies.map((item, idx) => {
                                                        return (
                                                            <option value={item._id}>{item.name}</option>
                                                        )
                                                    })
                                                }</Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="updated"
                                                component={renderDateTimeField}
                                                label="Updated"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="released"
                                                component={renderDateTimeField}
                                                label="Released"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="version"
                                                component={renderTextField}
                                                label="Version"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="views"
                                                component={renderTextField}
                                                label="Views"
                                            ></Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="sales"
                                                component={renderTextField}
                                                label="Sales"
                                            ></Field>
                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="tags"
                                                component={renderTagsField}
                                                label="Tags"
                                            ></Field>

                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="isFeatured"
                                                component={renderCheckField}
                                                label="Featured"
                                            />
                                        </Col>

                                    </Row>

                                </Col>

                                <Col lg="8">
                                    <Row>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="description"
                                                component={renderHtmlField}
                                                label="Description"
                                            ></Field>
                                        </Col>
                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="keywords"
                                                component={renderTagsField}
                                                label="Keywords"
                                            ></Field>
                                        </Col>

                                    </Row>

                                </Col>



                            </Row>
                        </Container>
                    </Col>

                    <Col lg="12">
                        <Container fluid className="form-box">
                            <Row>
                                <Col lg="6">
                                    <Field
                                        name="images"
                                        component={renderGalleryField}
                                        label="Detail images"
                                    ></Field>
                                </Col>
                                <Col lg="6">
                                    <Field
                                        name="screenshots"
                                        component={renderGalleryField}
                                        label="Screenshots"
                                    ></Field>
                                </Col>

                            </Row>
                        </Container>
                    </Col>



                    <Col lg="12">
                        <button className="button">Save</button>

                    </Col>

                </Row>

            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
