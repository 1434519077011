import React from 'react';
import { Field, reduxForm } from 'redux-form'


import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { renderTextField, renderImageField, renderSelectField } from './fields/fields';

const required = value => value ? undefined : "Required"

class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            technologies: []
        }
    }

    componentDidMount() {
        fetch('https://api.trendy.solutions/admin/fetch/categories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                categories: result
            })
        })

    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="6">
                        <Container fluid className="form-box">
                            <Row>


                                <Col lg="12">
                                    <h3 className="title">Category</h3>
                                    <h6 className="subtitle">Enter informations</h6>

                                </Col>


                                <Col lg="12" className="input-wrap">

                                    <Field
                                        name="image"
                                        component={renderImageField}
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">

                                    <Field
                                        name="name"
                                        component={renderTextField}
                                        label="Name"
                                    ></Field>
                                </Col>
                                <Col lg="12" className="input-wrap">
                                    <Field
                                        name="parentCategory"
                                        component={renderSelectField}
                                        label="Category"
                                    >{
                                            this.state.categories.map((item, idx) => {
                                                return (
                                                    <option value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }</Field>
                                </Col>
                                <Col lg="12" className="input-wrap">

                                    <Field
                                        name="breadcrumb"
                                        disabled
                                        component={renderTextField}
                                        label="Breadcrumb"
                                    ></Field>
                                </Col>



                            </Row>
                        </Container>
                    </Col>





                    <Col lg="12">
                        <button className="button">Save</button>

                    </Col>

                </Row>

            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
