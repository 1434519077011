import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';
import edit from '../assets/svg/edit.svg';

import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>

                </div>

                <div className="items">
                    <h6>HOME</h6>
                    <ul>

                        <li>
                            <Link to='/' className={this.props[0].location.pathname == '/' ? 'active' : null}>
                                <Isvg src={home} />
                                Statistics
                            </Link>
                        </li>




                    </ul>




                    <h6>PRODUCTS</h6>
                    <ul>

                        <li>
                            <Link to='/collection/products' className={this.props[0].location.pathname == '/collection/products' ? 'active' : null}>
                                <Isvg src={list} />
                                All products
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/comments' className={this.props[0].location.pathname == '/collection/comments' ? 'active' : null}>
                                <Isvg src={list} />
                                All comments
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/products/new' className={this.props[0].location.pathname == '/collection/products/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New product
                            </Link>
                        </li>


                    </ul>


                    <h6>CATEGORIES</h6>
                    <ul>

                        <li>
                            <Link to='/collection/categories' className={this.props[0].location.pathname == '/collection/categories' ? 'active' : null}>
                                <Isvg src={list} />
                                All categories
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/categories/new' className={this.props[0].location.pathname == '/collection/categories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New category
                            </Link>
                        </li>


                    </ul>

                    <h6>TECHNOLOGIES</h6>
                    <ul>

                        <li>
                            <Link to='/collection/technologies' className={this.props[0].location.pathname == '/collection/technologies' ? 'active' : null}>
                                <Isvg src={list} />
                                All technologies
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/technologies/new' className={this.props[0].location.pathname == '/collection/technologies/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New technology
                            </Link>
                        </li>


                    </ul>

                    <h6>BLOG</h6>
                    <ul>

                        <li>
                            <Link to='/collection/blog' className={this.props[0].location.pathname == '/collection/blog' ? 'active' : null}>
                                <Isvg src={list} />
                                All blogs
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/blog/new' className={this.props[0].location.pathname == '/collection/blog/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New blog
                            </Link>
                        </li>


                    </ul>


                    <h6>BLOG CATEGORIES</h6>
                    <ul>

                        <li>
                            <Link to='/collection/blogCategories' className={this.props[0].location.pathname == '/collection/blogCategories' ? 'active' : null}>
                                <Isvg src={list} />
                                All categories
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/blogCategories/new' className={this.props[0].location.pathname == '/collection/blogCategories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                New category
                            </Link>
                        </li>


                    </ul>



                    <h6>PAGES</h6>
                    <ul>
                        <li>
                            <Link to='/collection/pages' className={this.props[0].location.pathname == '/collection/pages' ? 'active' : null}>
                                <Isvg src={list} />
                                All pages
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/pages/new' className={this.props[0].location.pathname == '/collection/pages/new' ? 'active' : null}>
                                <Isvg src={rocket} />
                                New page
                            </Link>
                        </li>


                    </ul>


                    <h6>SEO</h6>
                    <ul>
                        <li>
                            <Link to='/collection/seo' className={this.props[0].location.pathname == '/collection/seo' ? 'active' : null}>
                                <Isvg src={list} />
                                All links
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/seo/new' className={this.props[0].location.pathname == '/collection/seo/new' ? 'active' : null}>
                                <Isvg src={rocket} />
                                New link
                            </Link>
                        </li>


                    </ul>
                    <h6>SETTINGS</h6>
                    <ul>

                        <li>
                            <Link to='/settings' className={this.props[0].location.pathname == '/settings' ? 'active' : null}>
                                <Isvg src={settings} />
                                Edit
                            </Link>
                        </li>




                    </ul>


                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('token')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                LogOut
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div>

            </div>
        )
    }

};

export default Sidebar;