import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Page from '../containers/page';
import { Line, Polar, Bar } from 'react-chartjs-2';
import Isvg from 'react-inlinesvg';
import WroldMap from '../components/worldMap';

import moment from 'moment';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import userIcon from '../assets/svg/user.svg';
import cartIcon from '../assets/svg/cart.svg';
import rocketIcon from '../assets/svg/rocket.svg';


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);

        this.state = {
        };
    }


    componentDidMount() {
        if (!localStorage.token) {
            return;
        }

        fetch('https://api.trendy.solutions/admin/home', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                initialValues: result
            })
        })
    }

    save(data) {
        if (!localStorage.token) {
            return;
        }

        fetch('https://api.trendy.solutions/admin/home', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.log('redirect')
            this.props[0].history.push('/');
        })

    }

    render() {
        let chartData = {
            labels: ['Today', 'Yesterday'],
            datasets: [
                {
                    fill: true,
                    lineTension: 0.45,
                    //backgroundColor: 'rgba(130, 141, 177, 0.2)',
                    backgroundColor: 'rgba(33, 150, 243, 0.2)',
                    borderColor: '#2196F3',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#2196F3',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 2,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgb(255,255,255)',
                    pointHoverBorderColor: '#2196F3',
                    pointHoverBorderWidth: 7,
                    pointRadius: 6,
                    pointHitRadius: 10,
                    data: [10, 15]
                }
            ],
        };


        const polarData = {
            datasets: [{
              data: [
                11,
                16,
                7,
                3,
                14
              ],
              backgroundColor: [
                'rgba(253, 29, 29,0.3)',
                'rgba(78, 199, 68, 0.3)',
                'rgba(255, 206, 86, 0.3)',
                'rgba(130, 141, 177, 0.3)',
                'rgba(33, 150, 243, 0.3)'
              ],
              label: 'My dataset' // for legend
            }],
            labels: [
              'United States',
              'Germany',
              'China',
              'Russia',
              'Australia'
            ]
          };
          

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>STATISTICS</h3>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg="3">
                            <div className="info-box">
                                <Isvg src={rocketIcon} />
                                <div>
                                    <h6>Visits</h6>
                                    <p>176028</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="info-box">
                                <Isvg src={cartIcon} />
                                <div>
                                    <h6>Sales</h6>
                                    <p>1860 / 15761 $</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg="3">
                            <div className="info-box">
                                <Isvg src={userIcon} />
                                <div>
                                    <h6>Users</h6>
                                    <p>120</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="info-box">
                                <Isvg src={userIcon} />
                                <div>
                                    <h6>Users</h6>
                                    <p>120</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg="6">
                            <Container fluid className="form-box">
                                <Row>
                                    <Col lg="12">
                                        <h3 className="title">Downloads map</h3>
                                        <h6 className="subtitle">Per country</h6>

                                    </Col>
                                    <Col lg="12">

                                        <WroldMap />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>

                        <Col lg="6" >
                            <Container fluid className="form-box form-box-fluid-height">
                                <Row>
                                    <Col lg="12">
                                        <h3 className="title">Downloads</h3>
                                        <h6 className="subtitle">Top 5 countires</h6>

                                    </Col>
                                    <Col lg="12">
                                        <Polar data={polarData} options={{
                                            responsive: true,
                                            legend: { display: true },
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        drawBorder: false
                                                    }
                                                }]

                                            },

                                        }} />

                                    </Col>
                                    
                                </Row>
                            </Container>
                        </Col>


                        <Col lg="6" >
                            <Container fluid className="form-box">
                                <Row>
                                    <Col lg="12">
                                        <h3 className="title">Downloads</h3>
                                        <h6 className="subtitle">Last 7 days</h6>

                                    </Col>
                                    <Col lg="12">
                                        <Line data={chartData} options={{
                                            responsive: true,
                                            legend: { display: false },
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        drawBorder: false
                                                    }
                                                }]

                                            },

                                        }} />

                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="6" >
                            <Container fluid className="form-box">
                                <Row>
                                    <Col lg="12">
                                        <h3 className="title">Downloads</h3>
                                        <h6 className="subtitle">This year</h6>

                                    </Col>
                                    <Col lg="12">
                                        <Bar data={chartData} options={{
                                            responsive: true,
                                            legend: { display: false },
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        drawBorder: false
                                                    }
                                                }]

                                            },

                                        }} />

                                    </Col>
                                </Row>
                            </Container>
                        </Col>

                    </Row>

                </Container>


            </div>
        );
    }
}

export default Page(HomePage);