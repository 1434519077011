import React from 'react';

import Text from './text';
import Textarea from './textarea';
import Image from './image';
import Html from './html';
import Gallery from './gallery';
import Tags from './tags';
import Select from './select';
import Section from './section';
import DateTime from './datetime';
import MultiSelect from './multiselect';
import Check from './check';


export const renderCheckField = ({
    input,
    label,
    meta: { touched, error },
}) => (

        <Check
            label={label}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )


export const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )
    export const renderMultiSelectField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
        children,
        additionalAction,
        scope,
    }) => (
    
            <MultiSelect
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                additionalAction={additionalAction}
                scope={scope}
                {...input}
                children={children}
            />
        )
    

export const renderTagsField = ({
    input,
    placeholder,
    label,
    multilang,
    lang,
    meta: { touched, error },
}) => (

        <Tags
            placeholder={placeholder}
            label={label}
            multilang={multilang}
            lang={lang}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

export const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang,
    disabled
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}
            disabled={disabled}

            {...input}
        />
    )

export const renderDateTimeField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <DateTime
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )


export const renderSectionField = ({
    input,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Section
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )


export const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )

export const renderHtmlField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Html
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )

export const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Image
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

export const renderGalleryField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Gallery
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            lang={lang}
            multilang={multilang}
            {...input}
        />
    )
