import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import visitIcon from '../assets/svg/visit.svg';
import deleteIcon from '../assets/svg/delete.svg';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import moment from 'moment';

class ContactListPage extends Component {
    constructor(props) {
        super(props);
        this.deletePage = this.deletePage.bind(this);
        this.fetchPages = this.fetchPages.bind(this);


        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.fetchPages();


    }

    fetchPages() {
        if (!localStorage.token) {
            return;
        }

        fetch('https://api.trendy.solutions/admin/fetch/requests', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })


    }

    deletePage(id) {
        if (!localStorage.token) {
            return;
        }

        fetch('https://api.trendy.solutions/admin/delete/requests/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            },
        }).then((res) => this.fetchPages())
    }
    render() {

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid className="table">

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>Alle</h3>
                        </Col>
                    </Row>
                    <Row className="table-head">
                        <Col lg="2">

                            <span className="name">AUTO</span>
                        </Col>
                        <Col lg="2">

                            <span className="name">NAME</span>
                        </Col>
                        <Col lg="2">

                            <span className="name">TELEFON</span>
                        </Col>

                        <Col lg={{ size: 2 }}>

                            <span className="name">E-MAIL</span>
                        </Col>
                        <Col lg="1">

                            <span className="name">DATE</span>
                        </Col>

                        <Col lg="2" className="actions">

                            <span className="name">OPTIONEN</span>
                        </Col>

                    </Row>
                    {
                        this.state.items.map((item, idx) => {
                            return (
                                <Row className="table-row">
                                    <Col lg="2">
                                        <span className="value">{item.make} {item.model} {item.year}</span>
                                    </Col>
                                    <Col lg="2">
                                        <span className="value">{item.sex} {item.name}</span>
                                    </Col>
                                    <Col lg="2">
                                        <span className="value">{item.phone}</span>
                                    </Col>

                                    <Col lg={{ size: 2 }}>
                                        <span className="value">{item.email}</span>
                                    </Col>
                                    <Col lg="1">
                                        <span className="value">{moment.unix(item.timestamp).format('DD.MM.YYYY')}</span>
                                    </Col>

                                    <Col lg="2" className="actions">
                                        <button onClick={() => this.setState({ mail: item.mail })}> <Isvg src={visitIcon} /></button>
                                        <button onClick={() => this.deletePage(item._id)}><Isvg src={deleteIcon} /></button>

                                    </Col>

                                </Row>

                            )
                        })
                    }

                </Container>

                {this.state.mail ?
                    <div className="mail-modal-wrap">
                        <div>
                            <iframe srcDoc={this.state.mail} ref={(node) => this.iframe = node}></iframe>
                            <button onClick={() => this.setState({mail: null})} className="button">Close</button>
                        </div>

                    </div>
                    :
                    null
                }

            </div>
        );
    }
}

export default Page(ContactListPage);